<template>
  <div id="nav">
    <router-link to="/">GCJ02坐标拾取</router-link> |
    <router-link to="/about">其它坐标转GCJ02坐标</router-link>
  </div>
  <el-config-provider :locale="locale">
    <div class="container">
      <router-view />
    </div>
  </el-config-provider>
</template>

<script>
import { ElConfigProvider } from "element-plus";

import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  name: "App",
  components: { ElConfigProvider },
  setup() {
    return {
      locale: zhCn,
    };
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#container {
  position: absolute;

}
</style>
